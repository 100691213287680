import './css/global.css';
import Header from './components/header';
import Mobilehome from './components/mobilehome';
import Home from './components/home';
import WWM from './components/waaromwaffelymobile';
import WIWM from './components/wieiswaffelymobile';
import MPC from './components/Mobilepicture';
import HWHM from './components/hoewerkthetmobile';
import IWM from './components/ingredientenmobile';
import CM from './components/contactmobile';
import Footer from './components/footer';
import Gewetenswaardigheden from './components/gewetenswaardigheden'
import StroopwafelTiramisu from './components/gewetenswaardighedenStroopwafelTiramisu'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition';
import Overons from './components/overons'
import ScrollToTop from './components/scrolltotop'

function App() {
  return (
    <Router>
      <ScrollToTop>
    <div className="App">
        <Switch>
           {/* <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    > */}
        <Route path="/" exact component={Homepage}/>
        <Route path="/overons" exact component={Overons}/>
        <Route path="/gewetenswaardigheden" exact component={Gewetenswaardigheden}/>
        <Route path="/StroopwafelTiramisu" exact component={StroopwafelTiramisu}/>
        {/* </AnimatedSwitch> */}
        </Switch>
    </div>
    </ScrollToTop>
    </Router>
  );
}

const Homepage = () => (
  <div>
    <Header/>
    <Mobilehome/>
    <Home/>
    <WIWM/>
    <WWM/>
    <MPC/>
    <HWHM/>
    <IWM/>
    <CM/>
    <Footer/>
  </div>
)
export default App;

