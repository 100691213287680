// import Logo from '../images/logo.png';
import '../css/gewetenswaardigheden.css';
import { Link } from 'react-router-dom'

function Mobilehome() {
  const linkStyle = {
    textDecoration: 'none',
    backgroundColor: 'black',
    color: 'white',
    padding: '10px 30px',
    borderRadius: '20px',
    fontSize: '14px'
  }
  return (
    <div className="nieuwsdivone">
      <div className="sideimagenieuws1"></div>
        <div className="textdivnieuws1">
            <p className="nieuwstext"> <h2 className="nieuwstoptitle">Stroopwafel tiramisu</h2>
            <h4 className="nieuwsondertitle">45 min.</h4>
            Maak het meeste met dit 
            lekkere dessert voor een 
            perfecte kerstontbijt, 
            lunch of diner, want er 
            altijd plek voor een toetje! 
            Met kerst komt goed voedsel 
            en dit recept is gegarandeerd 
            om iedereens kerst een succes 
            te maken!<br></br><br></br>
            <Link className="onclickreceptenbutton" to='/StroopwafelTiramisu'>
             <a class="receptenbutton" >MEER INFO</a>
            </Link>
            </p>
            </div>
        </div>
  );
}

export default Mobilehome;
