import '../css/hoewerkthetmobile.css';
import Handshake from '../images/handshake.png';
import Stroopwafel from '../images/stroopwafel.png';
import Decision from '../images/decision.png';

function Hoewerkthetmobile() {
  return (
    <div className="hoewerkthetmobile">
        <h2 className="titleW ipadhwh">Hoe werkt het?</h2>
        <div className="ipadhwhline bottomlinetitle"></div>
        <div className="hoewerkthetM hwhlinks">
             <img class="Handshake" src={Handshake} alt="Handshake" />
             <h3 class="wwtitles">Kennismaking</h3>
             <p class="wwtexts hwhtexts">een demonstratie zegt meer dan 1000 woorden en proeven is goud, 
             daarom komen we graag langs om u ter plekke een kwaliteit stroopwafel te bereiden
             </p>
        </div>
        <div className="hoewerkthetM hwhmidden">
             <img class="Stroopwafel" src={Stroopwafel} alt="Stroopwafel" />
             <h3 class="wwtitles">Proefperiode</h3>
             <p class="wwtexts hwhtexts">Probeer het zelf. U kunt de Waffely heater 2 weken te 
             leen krijgen om de werking, het product en reactie van klanten uit te proberen.
             </p>
        </div>
        <div className="hoewerkthetM hwhrechts">
             <img class="Decision" src={Decision} alt="Decision" />
             <h3 class="wwtitles">Vervolg</h3>
             <p class="wwtexts hwhtexts">En tot slot krijgt u na de proefperiode te keuze om door te gaan met het verkopen van de beste stroopwafels met de heater!</p>
        </div>
    </div>
  );
}

export default Hoewerkthetmobile;
