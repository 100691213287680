import Nieuwsone from '../components/nieuws1';
import Nieuwstwo from '../components/nieuws2';
import Nieuwsthree from '../components/nieuws3';
import Header from '../components/header';
import Footer from '../components/footer';

function Gewetenswaardigheden() {
  return (
    <div className="nieuwscontainer">
        <Header/>
        <h3 className="titleW ipadwaarom gewetenswaardighedentitle">Gewetenswaardigheden</h3>
        <div className="bottomlinetitle ipadwaaromline gewetenswaardighedenline" ></div>
        <Nieuwsthree/>
        <Nieuwsone/>
        <Nieuwstwo/>
        <Footer/>
    </div>
  );
}

export default Gewetenswaardigheden;
