// import Logo from '../images/logo.png';
import '../css/gewetenswaardigheden.css';

function Mobilehome() {
  return (
    <div className="nieuwsdivtwo bottomnieuws">
        <div className="textdivnieuws1">
            <p className="nieuwstext2"> <h2 className="nieuwstoptitle">Restaurant De Kolk</h2>
            <h4 className="nieuwsondertitle"> Spaarndam</h4>
            Hier kunt u onze stroopwafel proeven bij hun lekkere desert, cheesecake met warme stroopwafel. 
of gewoon bij een koffie of thee! <br></br><br></br>
            <b>Bezoek <a className="nieuwslinks" href="https://restaurantdekolk.nl/" target="blank">hier</a> de website van restaurant De Kolk!</b>
            </p>
            </div>
            <div className="sideimagenieuws2"></div>
        </div>
  );
}

export default Mobilehome;
