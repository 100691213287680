import '../css/footer.css';
import Instagram from '../images/instagram.png';
import Facebook from '../images/facebook.png';


function Footer() {
  return (
    <div className="footer">
        <div className="footercurve">
        </div> 
        <div className="footerdata">
        <p className="socialmediafooter"><h3>Volg ons op sociale media!</h3>
        <a className="socialmedialinks" rel="noreferrer" href="https://www.instagram.com/waffelynl/?hl=en" target="_blank"><img alt="instagramicon" className="" src={Instagram}></img></a>
        <a className="socialmedialinks" rel="noreferrer" href="https://www.facebook.com/groups/3093765647381281" target="_blank"><img alt="facebookicon" className="" src={Facebook}></img></a>
        </p>
        <p className="contactgegevensfooter"><h3>Contactgegevens</h3>
        Van Nesstraat 11 <br></br>
        2024 DK Haarlem <br></br>
        06-12345678 <br></br>
        Waffelynl@gmail.com <br></br>
        </p>
        </div>
    </div>
  );
}

export default Footer;
