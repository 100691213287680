import '../css/wieiswaffelymobile.css';
// import Arrow from '../images/downarrow.png';

function Wieiswaffelymobile() {
  return (
    <div className="wieiswaffelymobile">
        <h2 className="titleW">Over waffely</h2>
        <div className="bottomlinetitle"></div>
        <h3 className="wzwtitles">Wie zijn wij?</h3>
        <p className="wzwtexts">Weet je nog die eerste keer dat je met je vader of moeder naar de 
        markt ging en daar een warme, geurende stroopwafel kreeg? Wel, deze 
        beleving wil Waffely (een Start-Up) geven op elke plaats en tijd. Om dit te doen heeft 
        Waffely de stroopwafel belevings technisch onderzocht en de route van fabriek naar klant 
        geoptimaliseerd. Wij maken gebruik van de traditionele stroopwafel, gebakken met puur 
        natuurlijke grondstoffen, zonder gebruik van conservering middelen. Met ons werkproces en 
        onze speciale heater, is er geen verlies van stroopwafels en kan de stroopwafel in minder 
        dan 2 minuten geserveerd worden met de kwaliteit van de "marktstroopwafel".

        </p>
        <h3 className="wzwtitles">Wat is onze missie?</h3>
        <p className="wzwtexts">Onze missie is de glimlach van het kind terug brengen 
        bij iedereen die onze stroopwafels eet. Hiernaast ondersteunen wij goede doelen, 
        zoals bij onze actie voor de zorg, waarbij wij 500 stroopwafels uitdeelden 
        gedurende corona bij een ziekenhuis. Wilt u een goed doel ondersteunen? Neem dan
        contact met ons op.
        </p>
    </div>
  );
}

export default Wieiswaffelymobile;
