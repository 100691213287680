import '../css/contactmobile.css';
import Home from '../images/home.png';
import Phones from '../images/phones.png';
import Mail from '../images/mail.png';

function Contactmobile() {
  return (
    <div className="contactmobile">
        <h2 className="titleW ipadcontact">Contact</h2>
        <div className="bottomlinetitle ipadcontactline"></div>
        <div className="contactM">
             <img class="Home" src={Home} alt="Home" />
             <h3 class="wwtitles">Bezoek ons</h3>
             <p class="wwtexts">Maak nu een afspraak voor een demonstratie of gesprek!
             </p>
        </div>
        <div className="contactM">
             <img class="Phones" src={Phones} alt="Phones" />
             <h3 class="wwtitles">Bel ons</h3>
             <p class="wwtexts">Wij staan u graag te woord. Stuur een whats app boodschap voor een afspraak voor een gesprek, of bel, 06 27852056.</p>
        </div>
        <div className="contactM">
             <img class="Mail" src={Mail} alt="Mail" />
             <h3 class="wwtitles">Mail ons</h3>
             <p class="wwtexts">Of neem gerust contact met ons op via waffelynl@gmail.com</p>
        </div>
    </div>
  );
}

export default Contactmobile;
