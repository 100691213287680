// import Logo from '../images/logo.png';
import '../css/home.css';
import Arrow from '../images/downarrow.png';
import { Link } from 'react-router-dom'

 const navStyle = {
    textDecoration: 'none'
  }

function Home() {
  return (
    <div className="home">
        <div className="backgroundimagemobilehome">
            <h1 className="titlemobilehome">Warme, knapperige stroopwafels</h1>
            <h2 className="subtitlemobilehome">Sneller, makkelijker en lekker dan ooit</h2>
            <Link style={navStyle} to='/overons'>
       <a href="#wieiswaffely" className="homebutton">Ontdek waffely</a>
        </Link>
{/*            
            <div className="arrowcheat">
            <a id="waaromwaffely" className="homearrow"  ><img alt="arrow" className="arrowsize" src={Arrow}></img></a>
            </div> */}
        </div>
    </div>
  );
}

export default Home;
