import Logo from '../images/logo.png';
import '../css/header.css';
import { Link } from 'react-router-dom'

function Header() {

  const navStyle = {
    textDecoration: 'none'
  }

  return (
    <div className="Header">
      <Link style={navStyle} to='/'>
      <img class="Logo" src={Logo} alt="Logo" />
      </Link>
      <div class="nav">
        <Link style={navStyle} to='/'>
        <a class="navlinks" >HOME</a>
        </Link>
        <Link style={navStyle} to='/overons'>
        <a class="navlinks">OVER&nbsp;ONS</a>
        </Link>
        <Link style={navStyle} to='/gewetenswaardigheden'>
        <a class="navlinks" >GEWETENSWAARDIGHEDEN</a>
        </Link>
      </div>
    </div>
  );
}

export default Header;
