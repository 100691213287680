// import Logo from '../images/logo.png';
import '../css/mobilehome.css';
import Arrow from '../images/downarrow.png';

function Mobilehome() {
  return (
    <div className="mobilehome">
        <div className="backgroundimagemobilehome">
            <h1 className="titlemobilehome">Warme, knapperige stroopwafels</h1>
            <h2 className="subtitlemobilehome">Sneller, makkelijker en lekker dan ooit</h2>
            <a href="#wieiswaffely" className="homebutton">Ontdek waffely</a>
            {/* <div className="arrowcheat">
            <a id="wieiswaffely" className="homearrow" href="#wieiswaffely" ><img alt="arrow" className="arrowsize" src={Arrow}></img></a>
            </div> */}
            <div className="mobilebuffer" ></div>
        </div>
    </div>
  );
}

export default Mobilehome;
