import audio from '../images/bedankt_brunei_stroopwafel.ogg';
import '../css/gewetenswaardigheden.css';

function Mobilehome() {
  return (
    <div className="nieuwsdivtwo">
        <div className="textdivnieuws1">
            <p className="nieuwstext2"> <h2 className="nieuwstoptitle">Stroopwafel Delivery</h2>
            <h4 className="nieuwsondertitle"> Brunei</h4>
            Special delivery of best Waffely stroopwafels in Brunei worked out.
            Reaction of children Priceless!<br></br><br></br>
            <audio controls>
           <source src={audio} type="audio/ogg"></source>
            Your browser does not support the audio element.
            </audio>
            </p>
           
            </div>
            <div className="sideimagenieuws3"></div>
        </div>
  );
}

export default Mobilehome;
