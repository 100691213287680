// import Logo from '../images/logo.png';
import '../css/mobilePicture.css';

function MobilePicture() {
  return (
    <div className="mobilePicture">
    </div>
  );
}

export default MobilePicture;
