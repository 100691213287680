import Header from './header';
import Footer from './footer';
import '../css/receptendescriptie.css';
import { Link } from 'react-router-dom'

function Gewetenswaardigheden() {


  return (
    <div className="nieuwscontainer noscrollrecepten">
        <Header/>
        <div className='receptdescriptie'>
        <Link  className="backStyle" to='/gewetenswaardigheden'>
             <a class="receptenbutton" >&#10005;</a>
        </Link>
        <p className="titlesection"> 
          <h2 className="recepttoptitle">Stroopwafel Tiramisu</h2>
          <h4 className="receptondertitle">45 min.</h4>
        </p>
        <p className="recepttext">
        <div className="receptingredrienten"><b>Ingrediënten</b>
        <br></br>
        - 125 ml koffie<br></br>
        - 250 ml slagroom<br></br>
        - 1 zakje vanillesuiker<br></br>
        - 125 g mascarponecréme<br></br>
        - 2 el koffie<br></br>
        - 4 stroopwafels<br></br>
        - 2 el cacaopoeder<br></br>
        <br></br>
        </div>
        <div className="receptbenodigdheden">
        <b>Benodigdheden</b><br></br>
        - Mixer<br></br>
        <br></br>
        </div>
        <div className="receptstappen">
        <b>Stappen</b><br></br>
        1. Zet 125 ml koffie en laat deze afkoelen<br></br>
        <br></br>
        2. Klop de slagroom samen met de vanillesuiker tot een schuimige massa.
        Als de slagroom luchtig is, voeg dan de mascarponecréme en 2 eetlepels koffie toe. 
        Meng voorzichtig op lage snelheid tot een zacht mengsel.<br></br>
        <br></br>
        3. Snijd de stroopwafels op maat van de kom die je gaat gebruiken.
        Breek de stroopwafels in twee stukken, zodat je de boven en onderkant van de stroopwafels 
        los van elkaar hebt.<br></br>
        <br></br>
        4. Doop de stroopwafelhelften in de koffie. 
        Laat de stroopwafels ongeveer 30 seconden de koffie intrekken. 
        Plaats vervolgens 1 stroopwafel op de bodem van de kom en herhaal dit voor alle porties.<br></br>
        <br></br>
        5. Leg de helft van het roommengsel op de stroopwafelhelften. 
        Doop en laag de andere stroopwafelhelften en bedezek ze met het resterende roommengsel.<br></br>
        <br></br>
        6. Laat de stroopwafel tiramisu minimaal 30 minuten afkoelen. Bestrooi na het koelen de bovenste laag met een 
        dichte laag cacaopoeder. Dit is een klassieke finishing touch voor het tiramisu-desert.<br></br>
        <br></br>
        </div>
        </p>
        </div>
        <Footer/>
    </div>
  );
}

export default Gewetenswaardigheden;
