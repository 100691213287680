import '../css/waaromwaffelymobile.css';
import Medal from '../images/medal.png';
import Fingersnap from '../images/fingersnap.png';
import Klok from '../images/time.png';
import Preservatives from '../images/no-preservatives.png';
import Waffelyverticallogo from '../images/waffelyvertical.png';
// import Arrow from '../images/downarrow.png';

function Waaromwaffelymobile() {
  return (
    <div className="waaromwaffelymobile">
        <h2 className="titleW ipadwaarom">Waarom waffely?</h2>
        <div className="bottomlinetitle ipadwaaromline" ></div>
        <div className="waffelyverticallogo"><img class="Waffelylogo" src={Waffelyverticallogo} alt="Waffelylogo" /></div>
        <div className="kwaliteitM">
             <img class="Medal" src={Medal} alt="Medal" />
             <h3 class="wwtitles">Kwaliteit</h3>
             <p class="wwtexts">Een top kwaliteit warme stroopwafel, op iedere plek en ieder moment.</p>
        </div>
        <div className="kwaliteitM">
             <img class="Fingersnap" src={Fingersnap} alt="Vingersnap" />
             <h3 class="wwtitles">Gebruiksgemak</h3>
             <p class="wwtexts">Eenvoudig te bereiden, snel en altijd de juiste temperatuur en kwaliteit!</p>
        </div>
        <div className="kwaliteitM">
             <img class="Klok" src={Klok} alt="klok" />
             <h3 class="wwtitles">Zero waste</h3>
             <p class="wwtexts">Rechtstreeks uit de vriezer op te warmen voor een consistente knapperigheid. Dus geen tijd- en voedsel waste!</p>
        </div>
        <div className="kwaliteitM">
             <img class="Preservatives" src={Preservatives} alt="Preservatives" />
             <h3 class="wwtitles">Puur natuur</h3>
             <p class="wwtexts">Er zitten geen conserveringsmiddelen in de stroopwafels.</p>
        </div>
    </div>
  );
}

export default Waaromwaffelymobile;
