// import Logo from '../images/logo.png';
import '../css/ingredientenmobile.css';

function Mobilehome() {
  return (
    <div className="ingredientenhome">
      <div className="backgroundimagedesktopingredienten"></div>
        <div className="backgroundimagemobileingredienten">
            <h2 className="titleW">Ingredienten</h2>
            <div className="bottomlinetitle ipadingredientenline" ></div>
            <p className="ingrediententext"> <b>Het volgende zit in onze bijgeleverde stroopwafels:</b>
            <br className="skipenter"></br>
            <br></br>
            - Glucose-fructose stroop<br></br>
            - Tarwebloem<br></br>
            - Palm-, raap- en zonnebloem olie<br></br>
            - Suiker<br></br>
            - Roomboter<br></br>
            - Weipoeder<br></br>
            - Tarwezetmeel<br></br>
            - Sojameel<br></br>
            - Rijsmiddel: E500<br></br>
            - Zout<br></br>
            - Emulgatoren: E471<br></br>
            - Scharrelei<br></br>
            - Kaneel<br></br>
            <br></br>
            <b>KAN SPOREN VAN NOTEN BEVATTEN</b></p>
            </div>
        </div>
  );
}

export default Mobilehome;
